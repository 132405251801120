import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import Home from "./pages/Home";
import NotFound404 from "./pages/NotFound404";
import Privacy from "./pages/Privacy";

const App = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  </>
);

export default App;
