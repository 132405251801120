import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";

const Document = ({ markdown }: { markdown: string }) => {
  const newTheme = {
    h3: ({ children }: any) => {
      return (
        <Heading mt={16} mb={4} fontSize={"xl"}>
          {children}
        </Heading>
      );
    },
    p: ({ children }: any) => {
      return (
        <Text mb={4} fontSize={"large"}>
          {children}
        </Text>
      );
    },
    a: ({ children, href }: any) => {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Text display={"inline"} textDecor={"underline"}>
            {children}
          </Text>
        </a>
      );
    },
  };
  return (
    <Box maxW="100%">
      <Center>
        <Box
          maxW="700px"
          py={16}
          px={{
            base: 8,
            md: 16,
          }}
          fontSize="large"
        >
          <Box opacity={0.7} mb={8}>
            <Link to="/">← Back to Daypay</Link>
          </Box>
          <Text>
            <ReactMarkdown
              components={ChakraUIRenderer(newTheme)}
              children={markdown}
              skipHtml
            />
          </Text>
        </Box>
      </Center>
    </Box>
  );
};

export default Document;
