import {
  Flex,
  Stack,
  Text,
  Image,
  Box,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Home = () => {
  const breakpoint = useBreakpointValue({ base: "base", md: "md" }, "base");
  return (
    <Flex
      height={{ base: "100%", md: "100vh" }}
      direction={{ base: "column", md: "row" }}
      overflow={{ base: "auto", md: "hidden" }}
    >
      <Stack width={{ base: "100%", md: "40%" }}>
        <Stack
          alignItems={{ base: "center", md: "flex-start" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          textAlign={{ base: "center", md: "left" }}
          pl={{ base: 0, md: 20 }}
          height="100%"
        >
          <Image
            width={{ base: "130px", md: "120px" }}
            mt={8}
            src="images/daypay_logo.png"
          />
          <Stack
            alignItems={{
              base: "center",
              md: "flex-start",
            }}
          >
            <Heading
              mt={{ base: 16, md: -16 }}
              maxW={{ base: 300, md: 340 }}
              fontWeight="700"
              size={{ base: "3xl", md: "4xl" }}
            >
              Make your own time
            </Heading>
            <Text
              pt={{ base: 3, md: 5 }}
              maxW={{ base: 280, md: 300 }}
              fontSize={{
                base: "xl",
                md: "2xl",
              }}
              fontWeight={300}
            >
              Beautiful invoicing for freelancers and contractors
            </Text>
            {breakpoint === "base" && (
              <Image pt={16} width={"100%"} src="images/features_mobile.png" />
            )}
            <Flex
              justifyContent={{ base: "center", md: "flex-start" }}
              pt={{ base: 8, md: 10 }}
            >
              <a
                href="https://apps.apple.com/gb/app/daypay/id1665741059"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src="/images/app-store.png"
                  alt="Download on the app store"
                  cursor="pointer"
                  height={{ base: "68px", md: "62px" }}
                />
              </a>
            </Flex>
          </Stack>
          <Stack
            pt={{ base: 12, md: 4 }}
            pb={{ base: 32, md: 16 }}
            alignItems={{ base: "center", md: "flex-end" }}
            flexDir={{ base: "column", md: "row" }}
            spacing={{ base: 10, md: 0 }}
            fontSize={{ base: "md", md: "md" }}
            fontWeight="bold"
          >
            <Link to="/about">
              <Text paddingRight={{ base: 0, md: 8 }}>About Us</Text>
            </Link>
            <a
              href="https://dayrate.frill.co/b/6m46g8v4/feature-ideas"
              target="_blank"
              rel="noreferrer"
            >
              <Text paddingRight={{ base: 0, md: 8 }}>Request Feature</Text>
            </a>
            <Link to="/privacy">
              <Text>Privacy Policy</Text>
            </Link>
          </Stack>
        </Stack>
      </Stack>
      {breakpoint === "md" && (
        <Flex flex={1}>
          <Box
            mt={"-15%"}
            width={"100%"}
            height={"130%"}
            bgImg="images/features_web.png"
            bgPos="-15% -15%"
            bgRepeat="no-repeat"
            bgSize="cover"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Home;
