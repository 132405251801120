import { Box } from "@chakra-ui/react";
import Document from "../components/Document";

const markdown = `
### Hi, I'm Dom, the creator of Daypay.

I designed Daypay as an invoicing solution for freelancers and contractors who bill by the day.

The app was designed with ease-of-use as it's central focus, my personal experience with [ADHD](https://www.youtube.com/watch?v=2fYg5hSgtug) informs the simplicity of all my designs (I literally can't use confusing software 🤷). It's pretty foolproof. 

I want this app to be genuinely useful, so I'm always up for hearing [ways to improve the app](https://dayrate.frill.co/b/6m46g8v4/feature-ideas).

If you're reading this you're probably one of the first users, so thank you for being a part of this journey.

Happy invoicing,

Dom
`;

const About = () => (
  <Box>
    <Document markdown={markdown} />
  </Box>
);

export default About;
